<template>
  <div class="c-path-detail">
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button type="button" class="twn-button text-xs" @click="goBack">
        Retour
      </button>
      <button
        type="button"
        class="twn-button text-xs danger"
        @click="showDeleteModal(data.item.id)"
        v-if="!creating"
      >
        Supprimer le parcours
      </button>
    </div>

    <div class="twn-card my-10">
      <div class="flex">
        <div v-if="creating" class="uppercase font-principal-bold text-sm">
          Création d'un nouveau parcours
        </div>
        <div v-else class="uppercase font-principal-bold text-sm">
          Éditer le parcours
        </div>
      </div>

      <div class="mt-8">
        <div class="flex items-center">
          <div class="flex-1 mr-8">
            <label>Nom de du parcours</label>
            <b-form-input v-model="fields.name" type="text" />
          </div>

          <div class="flex-1 mr-8">
            <label class="mr-8">Type de parcours</label>
            <v-select
              class="flex-1 mr-auto"
              :options="[
                'Parcours complet',
                'Parcours par bloc',
                'Parcours sur-mesure',
              ]"
              placeholder="Type du parcours"
              v-model="fields.type"
            />
          </div>
        </div>

        <div class="mt-4">
          <div class="flex-1 mr-8">
            <label class="mr-8">Scénarios associés</label>
            <v-select
              class="w-full"
              :options="scenariosOptions"
              multiple
              label="name"
              placeholder="Sélectionner des scénarios/révisions/EDL..."
              v-model="fields.scenarios"
              @search="fetchScenariosByName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "@/utils/utils";

export default {
  props: ["itemID"],

  data: () => ({
    creating: false,
    scenariosOptions: [],
    fields: {
      name: null,
      type: null,
    },
  }),

  computed: {
    ...mapGetters("EditItem", ["getCurrentID"]),
  },

  created() {
    if (this.getCurrentID === -1 || !this.itemID) {
      this.creating = true;
    }
  },

  methods: {
    fetchScenariosByName(name, loading) {
      loading(true);
      this.search(loading, name, this);
    },

    search: debounce((loading, name, vm) => {
      setTimeout(() => {
        loading(false);
        // @Kevin : tu peux ajouter ici ton call graphql qui recherche les utilisateurs
        // via le nom et virer le setTimeout qui simule ce call
        vm.scenariosOptions = [
          {
            id: 1,
            name: "Scénario1 ",
          },
          {
            id: 2,
            name: "Scénario 2",
          },
          {
            id: 3,
            name: "Scénario 3",
          },
        ];
      }, 400);
    }, 350),

    goBack() {
      this.$router.push({ name: "paths-list" });
    },

    showDeleteModal(id) {
      this.selectedID = id;
      this.$refs["delete-modal-detail"].show();
    },
  },
};
</script>