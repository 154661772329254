export default {
    namespaced: true,
    state: {
        showNav: false
    },
    getters:{
        showNav(state){
            return state.showNav
        }
    },
    actions: {
        toggleNav({state}, evt){
            evt.stopPropagation();
            state.showNav = state.showNav == true ? false : true;
        },
        closeNav({state}){
            state.showNav = false;
        },
        openNav({state}){
            state.showNav = true
        }
    }
}