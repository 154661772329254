<template>
    <div class="footer text-gray-header-text bg-white h-8 flex justify-end items-center text-sm pr-3 flex-wrap">
        <div>
            © Design & Development by<a href="http://thewalkingnerds.com/" target="blank" class="ml-1">TheWalkingNerds</a>.
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer'
}
</script>
<style lang="scss" scoped>
    .footer a {
        @apply text-blue-400 no-underline;
    }
</style>