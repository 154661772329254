import { GC_GET_MODAL_LIST, GC_ADD_MODAL_ONE, GC_UPDATE_MODAL_BY_ID, GC_DELETE_MODAL_ONE } from '@/graphql/modal'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_MODAL_LIST(state, modals) {
            state.list = (modals || []);
        }
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_MODAL_LIST })

            commit('SET_MODAL_LIST', response.data.modal)
        },
        async save(ctx, { id, title, text }) {
            // Create or update modal
            if (id) {
                // Update remote data
                const response = await apollo.mutate({
                    mutation: GC_UPDATE_MODAL_BY_ID,
                    variables: {
                        id,
                        title,
                        text
                    }
                })

                return (response && response.data && response.data.update_modal.returning[0] ? response.data.update_modal.returning[0].id : null)
            } else {
                // Add remote data
                const response = await apollo.mutate({
                    mutation: GC_ADD_MODAL_ONE,
                    variables: {
                        title,
                        text
                    }
                })

                return (response && response.data && response.data.insert_modal_one ? response.data.insert_modal_one.id : null)
            }
        },
        delete(ctx, id) {
            // Delete from db
            return apollo.mutate({
                mutation: GC_DELETE_MODAL_ONE,
                variables: { id }
            })
        },
    }
}