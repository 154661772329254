import gql from 'graphql-tag'

const GC_GET_EMBED_LIST = gql`
query GetEmbedList {
    embed {
    	id
    	url
    }
  }
`

const GC_ADD_EMBED_ONE = gql`
mutation AddEmbedOne($url: String = "") {
	insert_embed_one(object: {url: $url}) {
		id
	}
}
`

const GC_UPDATE_EMBED_BY_ID = gql`
mutation UpdateEmbedOne($id: uuid!, $url: String = "") {
	update_embed(where: {id: {_eq: $id}}, _set: {url: $url}) {
		affected_rows
    returning {
      id
    }
	}
}
`

const GC_DELETE_EMBED_ONE = gql`
mutation DeleteEmbed($id: uuid!) {
  delete_embed(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
`

export {
	GC_GET_EMBED_LIST,
	GC_ADD_EMBED_ONE,
	GC_UPDATE_EMBED_BY_ID,
	GC_DELETE_EMBED_ONE
}