<template>
  <div>
    <List
      listing="paths"
      :fields="fields"
      search-text="Rechercher un parcours"
      @select-item="onSelectItem"
    >
      <template v-slot:row-details="{ row }">
        <b-card>
          <b-table
            small
            :fields="[
              { key: 'name', label: 'Nom du parcours', sortable: true },
            ]"
            :items="row.item.promotions"
          >
          </b-table>
        </b-card>
      </template>

      <template v-slot:cell(associated_scenarios)="data">
        <div v-for="(scenario, idx) in data.item.scenarios" :key="idx">
          {{ scenario }}
        </div>
      </template>

      <template v-slot:cell(associated_sequences)="data">
        <div>{{ data.item.sequences.length }} sessions associés</div>
        <div>
          -> {{ getSequencesCountByStatut(data.item, "past") }} passée(s)
        </div>
        <div>
          -> {{ getSequencesCountByStatut(data.item, "pending") }} en cours
        </div>
        <div>
          -> {{ getSequencesCountByStatut(data.item, "coming") }} à venir
        </div>
      </template>

      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddClick"
          >
            Ajouter un nouveau parcours
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions } from "vuex";

export default {
  components: {
    List,
  },
  data: () => ({
    fields: [
      {
        key: "name",
        label: "Nom du parcours",
        sortable: true,
      },
      {
        key: "type",
        label: "Type de parcours",
        sortable: true,
      },
      {
        key: "associated_scenarios",
        label: "Scénarios associés",
        sortable: true,
      },
      {
        key: "associated_sequences",
        label: "Sessions associés",
        sortable: true,
      },
    ],
  }),

  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),

    getSequencesCountByStatut(path, type) {
      return path.sequences.filter((sequence) => sequence.statut === type)
        .length;
    },

    onAddClick() {
      this.clearCurrentID();
      this.$router.push({ name: "paths-create" });
    },

    onSelectItem(item) {
      item._showDetails = !item._showDetails;
    },
  },
};
</script>