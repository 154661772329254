<template>
	<div class="right-pane" :class="slide ? 'slide' : ''" v-show="isShow">
		<div class="mt-1 mb-6">
			<span class="title">
				{{ title }}
			</span>
			<div class="float-right cursor-pointer pt-1" @pointerdown.stop="hide">
				<img src="@/assets/images/NODE_CROSS.svg" />
			</div>
		</div>

		<div class="slot">
			<slot></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: "RightPanel",
	data() {
		return {
			isShow: false,
			slide: false
		}
	},
	props: {
		title: {
			type: String,
			required: false,
			default: null
		}
	},
	methods: {
		show() {
			this.isShow = true
			this.$emit("show")
		},
		hide() {
			this.isShow = false
			this.$emit("hide")
		},
		slideOut(){
			this.slide = true
		},
		slideIn(){
			this.slide = false
		}
	}
}
</script>
<style lang="scss">
.right-pane {
	@apply flex flex-col fixed bg-white right-0 bottom-0 z-10 w-1/3 p-6 pb-2;
	height: calc(100vh - 4rem);
	z-index: 2000;
	transition: all;
	transform: translateX(0);

	&.slide{
		transform: translateX(100vh);
	}

	.title {
		@apply text-xs uppercase font-principal-bold;
	}

	.slot{
		@apply overflow-auto;
	}
}
</style>
