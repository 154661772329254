import { GC_GET_PROPOSAL_LIST, GC_ADD_PROPOSAL_ONE, GC_UPDATE_PROPOSAL_BY_ID, GC_DELETE_PROPOSAL_ONE } from '@/graphql/proposal'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_PROPOSAL_LIST(state, proposals) {
            state.list = (proposals || []);
        }
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PROPOSAL_LIST })

            commit('SET_PROPOSAL_LIST', response.data.proposal)
        },
        async save(ctx, { id, texte }) {
            // Create or update proposal
            if (id) {
                // Update remote data
                const response = await apollo.mutate({
                    mutation: GC_UPDATE_PROPOSAL_BY_ID,
                    variables: {
                        id,
                        texte
                    }
                })

                return (response && response.data && response.data.update_proposal.returning[0] ? response.data.update_proposal.returning[0].id : null)
            } else {
                // Add remote data
                const response = await apollo.mutate({
                    mutation: GC_ADD_PROPOSAL_ONE,
                    variables: {
                        texte
                    }
                })

                return (response && response.data && response.data.insert_proposal_one ? response.data.insert_proposal_one.id : null)
            }
        },
        delete(ctx, id) {
            // Delete from db
            return apollo.mutate({
                mutation: GC_DELETE_PROPOSAL_ONE,
                variables: { id }
            })
        },
    }
}