import gql from 'graphql-tag'

const GC_GET_PROPOSAL_LIST = gql`
query GetProposalList {
    proposal {
    	id
    	texte
    }
  }
`

const GC_ADD_PROPOSAL_ONE = gql`
mutation AddProposalOne($texte: String = "") {
	insert_proposal_one(object: {texte: $texte}) {
		id
	}
}
`

const GC_UPDATE_PROPOSAL_BY_ID = gql`
mutation UpdateProposalOne($id: uuid!, $texte: String = "") {
	update_proposal(where: {id: {_eq: $id}}, _set: {texte: $texte}) {
		affected_rows
    returning {
      id
    }
	}
}
`

const GC_DELETE_PROPOSAL_ONE = gql`
mutation DeleteProposal($id:uuid!) {
  delete_proposal(where: {id: {_eq: $id}}) {
    affected_rows
  }
}

`

export {
	GC_GET_PROPOSAL_LIST,
	GC_ADD_PROPOSAL_ONE,
	GC_UPDATE_PROPOSAL_BY_ID,
	GC_DELETE_PROPOSAL_ONE
}