import gql from 'graphql-tag'

const GC_GET_NODE_TYPE_LIST = gql`
query GetNodeTypeList {
    node_type(order_by: {created_at: asc}) {
    	id
    	name
    	description
    	slug
    }
  }
`

export {
    GC_GET_NODE_TYPE_LIST
}