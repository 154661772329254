import { GC_GET_MESSAGE_LIST, GC_ADD_MESSAGE_ONE, GC_UPDATE_MESSAGE_BY_ID, GC_DELETE_MESSAGE_ONE } from '@/graphql/message'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_MESSAGE_LIST(state, messages) {
            state.list = (messages || []);
        }
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_MESSAGE_LIST })

            commit('SET_MESSAGE_LIST', response.data.message)
        },
        async save(ctx, { id, text, media_id, character_id, action_id }) {
            // Create or update message
            if (id) {
                // Update remote data
                const response = await apollo.mutate({
                    mutation: GC_UPDATE_MESSAGE_BY_ID,
                    variables: {
                        id,
                        text,
                        media_id,
                        character_id,
                        action_id
                    }
                })

                return (response && response.data && response.data.update_message.returning[0] ? response.data.update_message.returning[0].id : null)
            } else {
                // Add remote data
                const response = await apollo.mutate({
                    mutation: GC_ADD_MESSAGE_ONE,
                    variables: {
                        text,
                        media_id,
                        character_id,
                        action_id
                    }
                })

                return (response && response.data && response.data.insert_message_one ? response.data.insert_message_one.id : null)
            }
        },
        delete(ctx, id) {
            // Delete from db
            return apollo.mutate({
                mutation: GC_DELETE_MESSAGE_ONE,
                variables: { id }
            })
        },
    }
}