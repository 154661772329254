import gql from "graphql-tag"

const GC_GET_ENTITY_TYPE_LIST = gql`
query GetEntityTypes {
	entity_type {
		id
		name
		slug
	}
}
`

const GC_GET_ENTITY_LIST = gql`
query GetEntityList {
    entity(order_by: {identifier: asc}) {
    	id
    	identifier
      name
      description
    	slug
    	default
      type {
      	id
      	slug
      }
    }
  }
`

const GC_GET_ENTITY_BY_ID = gql`
query GetEntity($id: uuid!) {
	entity_by_pk(id: $id) {
		id
		identifier
		name
		description
		default
		slug
		data
		entity_type_id
	}
}
`

const GC_ADD_ENTITY_ONE = gql`
mutation AddEntity($entity: entity_insert_input!) {
	insert_entity_one(object: $entity) {
		id
	}
}`

const GC_UPDATE_ENTITY_BY_ID = gql`
mutation UpdateEntity($id: uuid!,
	$entity: entity_set_input!) {
	update_entity_by_pk(pk_columns: {id: $id},
	_set: $entity) {
		id
	}
}
`

const GC_DELETE_ENTITY_BY_ID = gql`
mutation DeleteEntity($id: uuid!) {
	delete_entity_by_pk(id: $id) {
		id
	}
}
`

export {
	GC_GET_ENTITY_TYPE_LIST,
	GC_GET_ENTITY_LIST,
	GC_GET_ENTITY_BY_ID,
	GC_ADD_ENTITY_ONE,
	GC_UPDATE_ENTITY_BY_ID, 
	GC_DELETE_ENTITY_BY_ID,
}