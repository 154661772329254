import { Node } from "tiptap"

export default class Hole extends Node {
	get name() {
		return "hole"
	}

	get schema() {
		return {
			attrs: {
				holeid: {
					type: Number,
					default: null
				}
			},
			inline: true,
			group: "inline",
			draggable: true,
			selectable: false,
			parseDOM: [
				{
					tag: "mark",
					getAttrs: (dom) => ({
						holeid: dom.getAttribute("holeid")
					})
				}
			],
			toDOM: (node) => ["mark", node.attrs]
		}
	}
	commands({ type }) {
		return (attrs) => (state, dispatch) => {
			const { selection } = state
			const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
			const node = type.create(attrs)
			const transaction = state.tr.insert(position, node)
			dispatch(transaction)
		}
	}

	get view() {
		return {
			props: ["node", "updateAttrs"],
			computed: {
				holeid: {
					get() {
						return this.node.attrs.holeid
					},
					set(val) {
						this.updateAttrs({
							holeid: val
						})
					}
				}
			},
			template: `<mark contenteditable="false" class="hole number" :data-id="holeid" :holeid="holeid" >{{holeid}}</mark>`
		}
	}
}
