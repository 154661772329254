<template>
    <div>
        <cooc-list 
            listing="organisations" 
            orderBy="name: asc"
            :previousPage="previousPage"
            :fields="fields" 
            :filters="filters" 
            modify-button-text="Éditer la fiche Office"
            :with-delete-button="false"
            searchText="Rechercher un office"
        >
            <template v-slot:buttons>
                <div class="md:flex flex-row justify-around hidden md:w-1/3">
                    <button type="button" class="twn-button danger whitespace-no-wrap overflow-hidden text-xs mr-2" @click="addOrga">Ajouter un office</button>
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab add" @clickItem="addOrga" color="#DC9799" :idx="0.5" title="Ajouter un office" icon="add" />
                </vue-fab>
            </template>
        </cooc-list>
    </div>
</template>
<script>

import List from '@/components/List'

import { mapActions } from 'vuex';

export default {
    name: 'OrganizationList',
    components: {'cooc-list':List},
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() { 
        return {
            fields: [
                {
                    key:'name',
                    label: 'Nom',
                    sortable: true,
                },
                {
                    key:'admin',
                    label: 'Gérant',
                    sortable: true,
                    db: `admin{
                            name
                        }`,
                    nested: 'name'
                }
            ],
            filters: [
                {
                    key:'admin',
                    label: 'Administrateur',
                    multiple: true
                },
                {
                    key:'name',
                    label: 'Nom'
                }
            ]
        }
    },
    methods: {
        ...mapActions('EditItem', ['clearCurrentID']),
        addOrga(){
            this.clearCurrentID();
            this.$router.push({name:'organisations-create'});
        },

        onSelectItem(item) {
            item._showDetails = !item._showDetails
        }
    }
}
</script>