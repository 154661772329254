import gql from 'graphql-tag'

const GC_GET_TRIGGER_TYPE_LIST = gql`
query GetTriggerTypeList {
    trigger_type {
    	id
    	name
    	description
    	slug
    }
  }
`

export {
    GC_GET_TRIGGER_TYPE_LIST
}