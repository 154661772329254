import gql from 'graphql-tag'

const GC_GET_LOCATION_LIST = gql`
query GetLocationList {
    location {
      id
      name
    }
  }
`

export {
    GC_GET_LOCATION_LIST
}