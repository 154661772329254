import { extend } from "vee-validate";
import { regex, email, min, max, oneOf, numeric, required } from 'vee-validate/dist/rules';

extend('email', {
  ...email,
  message: 'Vérifier le format du mail'
});

extend('required', {
  ...required,
  message: 'Vous devez remplir le champ {_field_}'
});

extend('regex', {
  ...regex,
  message: 'Vérifiez le format de {_field_}'
});

extend('min', {
  ...min,
  message: 'Le champ {_field_} doit faire au moins {length} caractères'
});

extend('max', {
  ...max,
  message: 'Le champ {_field_} doit faire au maximum {length} caractères'
});

extend('oneOf', {
  ...oneOf,
  message: 'Vérifiez le champ {_field_}'
});

extend('numeric', {
  ...numeric,
  message: 'Le champ {_field_} ne doit contenir que des chiffres'
});

extend('confirmed', {
  ...numeric,
  message: 'Les mots de passent ne correspondent pas'
});
