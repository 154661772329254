import gql from 'graphql-tag'

const GC_GET_VIDEO_PART_LIST = gql`
query GetVideoPartList {
    video_part {
      id
      media_id
      start_time
      end_time
      frame_id
    }
  }
`

const GC_ADD_VIDEO_PART_ONE = gql`
mutation AddVideoPartOne($media_id: uuid!, $start_time: bigint!, $end_time: bigint!, $frame_id: uuid = null) {
	insert_video_part_one(object: { media_id: $media_id, start_time: $start_time, end_time: $end_time, frame_id: $frame_id }) {
		id
	}
}
`

const GC_UPDATE_VIDEO_PART_BY_ID = gql`
mutation UpdateVideoPartOne($id: uuid!, $media_id: uuid!, $start_time: bigint!, $end_time: bigint!, $frame_id: uuid = null) {
	update_video_part(where: {id: {_eq: $id}}, _set: { media_id: $media_id, start_time: $start_time, end_time: $end_time, frame_id: $frame_id }) {
		affected_rows
    returning {
      id
    }
	}
}
`

const GC_DELETE_VIDEO_PART_ONE = gql`
mutation DeleteVideoPart($id:uuid!) {
  delete_video_part(where: {id: {_eq: $id}}) {
    affected_rows
  }
}

`

export {
	GC_GET_VIDEO_PART_LIST,
	GC_ADD_VIDEO_PART_ONE,
	GC_UPDATE_VIDEO_PART_BY_ID,
	GC_DELETE_VIDEO_PART_ONE
}