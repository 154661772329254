import { GC_GET_NODE_TYPE_LIST } from '@/graphql/node'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        list: [],
        type_list: []
    },
    mutations: {
        SET_NODE_TYPE_LIST(state, types) {
            state.type_list = types;
        }
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_NODE_TYPE_LIST })

            commit('SET_NODE_TYPE_LIST', response.data.node_type)
        }
    }
}