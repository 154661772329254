import apollo from '@/apolloClient'

import {
    GC_GET_ENTITY_TYPE_LIST,
    GC_GET_ENTITY_LIST,
    GC_GET_ENTITY_BY_ID,
    GC_ADD_ENTITY_ONE,
    GC_UPDATE_ENTITY_BY_ID,
    GC_DELETE_ENTITY_BY_ID,
} from '@/graphql/entity'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
        type_list: [],
    },
    mutations: {
        SET_ENTITY_LIST(state, entities) {
            state.list = (entities || []);
        },
        SET_ENTITY_TYPE_LIST(state, types) {
            state.type_list = types;
        },
        SET_ENTITY(state, entity) {
            if (!entity || !entity.id)
                return

            state.items[entity.id] = entity
        },
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_ENTITY_TYPE_LIST })

            commit('SET_ENTITY_TYPE_LIST', response.data.entity_type)
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_ENTITY_LIST })

            commit('SET_ENTITY_LIST', response.data.entity)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_ENTITY_BY_ID,
                variables: { id }
            })

            if (!response.data.entity_by_pk) {
                return
            }

            commit('SET_ENTITY', response.data.entity_by_pk)
        },
        async save({ dispatch }, data) {
            let response = null
            let result = {}

            const entity = {
                identifier: data.identifier,
                name: data.name,
                description: data.description,
                default: data.default,
                slug: data.slug,
                data: data.data,
                entity_type_id: data.entity_type_id,
            }

            // Update or add the entity
            if (data.id) {
                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_ENTITY_BY_ID,
                    variables: {
                        id: data.id,
                        entity
                    }
                })

                await dispatch('Logs/ContentLog', {
                    id: data.id,
                    action: 'content_update'
                }, { root: true })

                result.success = true
            } else {
                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_ENTITY_ONE,
                    variables: {
                        entity
                    }
                })

                await dispatch('Logs/ContentLog', {
                    id: response.data.insert_entity_one.id,
                    action: 'content_create'
                }, { root: true })

                result.id = response.data.insert_entity_one.id
            }

            return result
        }
    },
    async delete({ dispatch }, id) {
        await dispatch('Logs/ContentLog', {
            id,
            action: 'content_delete'
        }, { root: true })

        // Delete from db
        const response = await apollo.mutate({
            mutation: GC_DELETE_ENTITY_BY_ID,
            variables: { id }
        })

        return response
    },
}