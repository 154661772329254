import Vue from 'vue'
import { 
    BIconList, 
    BBreadcrumb, 
    BTable, 
    BPaginationNav, 
    BCard,
    BFormInput,
    BFormDatepicker,
    BFormTags,
    IconsPlugin,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BButton,
    ModalPlugin,
    BFormTextarea,
    ToastPlugin,
    BTableLite,
    BListGroup,
    BFormCheckboxGroup
} from 'bootstrap-vue'

Vue.component('BModal', BIconList)
Vue.component('BBreadcrumb', BBreadcrumb)
Vue.component('BListGroup', BListGroup)
Vue.component('BTable', BTable)
Vue.component('BTableLite', BTableLite)
Vue.component('BPaginationNav', BPaginationNav)
Vue.component('BCard', BCard)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormDatepicker', BFormDatepicker)
Vue.component('BFormTags', BFormTags)
Vue.component('BForm', BForm) 
Vue.component('BFormGroup', BFormGroup) 
Vue.component('BFormCheckbox', BFormCheckbox) 
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup) 
Vue.component('BButton', BButton) 
Vue.component('BFormTextarea', BFormTextarea) 

Vue.use(IconsPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin)